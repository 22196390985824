// ** Initial user ability
export const initialAbility = [
  {
    action: 'manage',
    subject: 'all',
    action: 'asesor',
    subject: 'ACL',
    action: 'domiciliario',
    subject: 'ACL',
    action: 'supervisor',
    subject: 'ACL'
  }
]

export const _ = undefined
